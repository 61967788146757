import styled from 'styled-components/macro';
import { SchoolLoginFields } from '../types';
import LoginSchoolForm from './LoginSchoolForm';

import LoginImage from '../../../img/loginImg.svg';

interface SchoolLoginTypes {
  title: string;
  onSignIn: (schoolFields: SchoolLoginFields) => void;
  loading: boolean;
  onForgotPasswordClickHandler: () => void;
  onRegisterClickHandler?: () => void;
}

export default function SchoolLogin({
  title, onSignIn, loading, onForgotPasswordClickHandler, onRegisterClickHandler,
}: SchoolLoginTypes) {
  return (
    <CreateSchooFormWrap>
      <Content>
        <MainHeader>
          {title}
        </MainHeader>
        <StyledText>Sign in with your Devices For Education account</StyledText>
        <LoginSchoolForm onSignIn={onSignIn} loading={loading} onForgotPasswordClickHandler={onForgotPasswordClickHandler} onRegisterClickHandler={onRegisterClickHandler} />
      </Content>
    </CreateSchooFormWrap>
  );
}

const CreateSchooFormWrap = styled.div`
  width: 100%;
  background: url(${LoginImage}) no-repeat right 150px center/40%, linear-gradient(to right, white 70%, #F1F9F8 70% 100%);
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MainHeader = styled.h1`
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 19px;
  color: #102F54;
`;

const StyledText = styled.p`
  color: #909DA8;
  margin-bottom: 39px;
`;

const Content = styled.div`
  padding: 66px 0 0 150px;
`;
